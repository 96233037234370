import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/account/characters": [~4,[2]],
		"/account/general": [~5,[2]],
		"/account/purchases": [~6,[2]],
		"/character/[characterUuid]": [~7],
		"/discord": [~8],
		"/download": [9],
		"/leaderboard": [~10],
		"/login": [11],
		"/logout": [~12],
		"/news": [~13],
		"/news/[slug]": [~14],
		"/pages/[slug]": [~15],
		"/shop": [~16],
		"/shop/cancel": [17],
		"/shop/success": [18],
		"/sign-up": [~19],
		"/sign-up/verify/failed": [21],
		"/sign-up/verify/success": [22],
		"/sign-up/verify/[token]": [~20],
		"/switch-language": [23],
		"/switch-language/[languageCode]": [~24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';